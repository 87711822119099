import React from 'react'
import MFooter from '../Partials/MFooter'
import MUpload from './MUpload'
import MHeader from '../Partials/MHeader'
import MHistory from './MHistory'

const MUser = () => {
  return (
    <div className=' d-flex flex-column justify-content-between' style={{
        height:"100vh"
    }}>
        <MHeader />
        {/* <MUpload /> */}
        {/* <MHistory /> */}
        <MFooter />
    </div>
  )
}

export default MUser