import { Breadcrumbs, Button, Chip, Link, Typography } from '@mui/material';
import React from 'react';
import DataTable from 'react-data-table-component';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { FileUploader } from "react-drag-drop-files";
import BlockIcon from '@mui/icons-material/Block';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { sampleData } from './sample';
import CommentIcon from '@mui/icons-material/Comment';
import AddCommentIcon from '@mui/icons-material/AddComment';
import AddMessageModal from './AddMessageModal';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { BallTriangle, ProgressBar } from 'react-loader-spinner';


const fileTypes = ["csv"];

function Employees() {
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [showUploadModal, setShowUploadModal] = React.useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false)
  const [loading, setLoading] = React.useState(true);
 
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleRowSelected = React.useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = React.useMemo(() => {
    const handleDelete = () => {

      if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.title)}?`)) {
        setToggleCleared(!toggleCleared);
        setData(data.filter(item => !selectedRows.some(selected => selected.title === item.title)));
      }
    };

    return (
      <Button key="delete" onClick={handleDelete} style={{ backgroundColor: 'red' }} icon>
        Delete
      </Button>
    );
  }, [data, selectedRows, toggleCleared]);

  const fetchEmployees = () => {
    axios
      .get("https://st-api.azgard.co.ke/api/Clients/GetClients")
      .then((response) => {
        console.log(response.data);
        setData(response.data.$values)
      })
      .catch((e) => {
        console.log(e);
      });
  };


  const columns = [
    
    {
      name: 'Full Name',
      selector: (row,index) => row.name,
      sortable: true,},
    // },{
    //   name: 'Email Address',
    //   selector: row => row.email,
    //   sortable: true,
    // },
    {
      name: 'PhoneNumber',
      selector: row => row.phoneNumber,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => {
        return (
          <Chip
            label="active"
            size="small"
            variant="filled"
            style={{ backgroundColor: "#C2FBEF", color: "green" }}
          />
        )
      },
      sortable: true,
    },
    
  ];

  const handleUpload = () => {
    setIsLoading(true);
    if (selectedFile) {
      // Create a FormData object to send the file
      const formData = new FormData();
      formData.append('clientsList', selectedFile);

      // Perform the upload using your preferred method (e.g., Axios)
      // Replace `uploadUrl` with the appropriate URL for your server-side upload endpoint
      const uploadUrl = 'https://st-api.azgard.co.ke/api/Clients/UploadClients';
      axios.post(uploadUrl, formData)
        .then(response => {
          // Handle the successful upload response
          console.log('Upload success:', response);
          setShowUploadModal(false);
          setIsLoading(false);

        })
        .catch(error => {
          setIsLoading(false);
          // Handle upload errors
          console.error('Upload error:', error);
        });
    }
  };

  React.useEffect(() => {
    fetchEmployees()

  }, [])

  const containerStyle = {
    height: "25vh", // Set the desired height here
    border: "1px solid #ccc", // Add a border for visibility
    padding: "10px", // Optional: Add padding for spacing
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  };

  return (
    <div style={{ width: "100%" }}>
      

      <div className="title my-3">
        <div role="presentation" >
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Dashboard
            </Link>

            <Typography color="text.primary">Clients</Typography>
          </Breadcrumbs>
        </div>
      </div>

      <Paper
        // component="form"
        sx={{ p: '14px 14px', display: 'flex', alignItems: 'center' }}
        className='w-100  justify-content-end'
        elevation={0}
      >
        <div className='w-100 justify-content-between d-flex'>
          <div className="searchbox d-flex" style={{ background: "whitesmoke" }}>
            <IconButton sx={{ p: '10px' }} aria-label="menu">
              <ClearIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 2, background: "whitesmoke" }}
              placeholder="Search Client"
              inputProps={{ 'aria-label': 'search google maps' }}
              className='ps-2'
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </div>

          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

          <div className="uploads">
          <Button 
            variant="contained" 
            startIcon={<DriveFolderUploadIcon />} 
            className='ms-2'
            onClick={()=>setShowUploadModal(true)}
          >
            Upload Clients
          </Button>
          <Button 
            variant="contained" 
            startIcon={<AddCommentIcon  />} 
            className='ms-2'
            onClick={()=>setShowModal(true)}
          >
            Create Message
          </Button>
          </div>
        </div>

      </Paper>


      <style>
        {`
          .rdt_TableCol:first-child {
            width: 50px; // Adjust the width as needed
          }
        `}
      </style>

        
      {
        showUploadModal ?
      <>
        <div className='bg-white'>
             <div className="p-4">
              <Divider />
             <div className="d-flex flex-column ">
             <h3 className='pt-4 pb-3'>Upload Clients CSV File</h3>
              <input type="file" accept="*" onChange={handleFileChange} />
              {/* <button onClick={handleUpload}>Upload</button> */}
             </div>
             </div>
             {
              isLoading ?
              <div className='d-flex w-100  p-4 flex-column'>
               <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#4fa94d"
                ariaLabel="ball-triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                />
                <p className='pt-4'>Uploading Clients...</p>
              </div>:
              <div className='d-flex'>
                  <Button 
                  variant="contained" 
                  color='success'
                  // startIcon={<DriveFolderUploadIcon />} 
                  className='ms-3 mx-2 mb-4'
                  onClick={()=>handleUpload()}
                  >
                    Confirm Upload
                  </Button>
                  <Button 
                      variant="contained"
                      color='error' 
                      // startIcon={<DriveFolderUploadIcon />} 
                      className='ms-3 mx-2  mb-4'
                      onClick={()=>setShowUploadModal(false)}
                  >
                    Cancel
                  </Button>
              </div>
             }
            
          </div>
      </>
      :
      <DataTable
              columns={columns}
              data={data}
              className="w-100"
              fixedHeader
              fixedHeaderScrollHeight="60vh"
              pagination
              selectableRows
              contextActions={contextActions}
              onSelectedRowsChange={handleRowSelected}
              clearSelectedRows={toggleCleared}

            />

      }
      

      <AddMessageModal show={showModal} handleClose={handleClose} clients={data} />
    </div>
  );
};

export default Employees
