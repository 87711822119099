import Modal from 'react-bootstrap/Modal';
import DoneIcon from '@mui/icons-material/Done';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';
import React, { useState } from 'react';

function AddMessageModal(props) {
  const [message, setMessage] = React.useState(null)
  const sendBulkSMS = (clients) => {
    // Endpoint URL for sending bulk SMS
    const uploadUrl = 'http://localhost:5111/api/Messaging/SendBulkSMS';
  
    // Transform clients data into bulk SMS payload
    const bulkSmsData = {
      bulkSms: clients.$values.map(client => ({
        to: client.phoneNumber,
        message: `Dear ${client.name} ${message}`
      }))
    };
  
    // Make a POST request to the endpoint with the bulk SMS data payload
    axios.post(uploadUrl, bulkSmsData)
      .then(response => {
        // Handle the successful response
        console.log('Bulk SMS sent successfully:', response.data);
      })
      .catch(error => {
        // Handle errors
        console.error('Error sending bulk SMS:', error);
      });
  };
  
  
  
  // Call the function with the clients data
  // sendBulkSMS();
  

  return (
    <>
      
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <TextField
          id="outlined-multiline-static"
          label="Message"
          multiline
          rows={8}
          onChange={(e)=>setMessage(e.target.value)}
          defaultValue="Type your Message"
          className='w-100'
        />
       <div className="d-flex justify-content-start mt-3">
       
       <Chip
            label={`All Clients Selected`}
            deleteIcon={<DoneIcon />} 
        />
       </div>
        </Modal.Body>
        <Modal.Footer>
        <Button 
            variant="contained" 
            className='me-3' 
            onClick={props.handleClose} 
            color="error"
        >Cancel</Button>
        <Button variant="contained" endIcon={<SendIcon />}  onClick={()=>sendBulkSMS(props.data)}>Send</Button>
         
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddMessageModal;