import { Button, Chip, Typography } from '@mui/material';
import React from 'react';
import DataTable from 'react-data-table-component';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { FileUploader } from "react-drag-drop-files";
import uploadIcon from "../../assets/upload-icon.png"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import dayjs from 'dayjs';
import { MuiFileInput } from 'mui-file-input';
import axios from 'axios';
import moment from 'moment';

const fileTypes = ["PDF", "PNG", "JPG"];

const data = [
  {
    id: 1,
    title: 'Beetlejuice',
    year: '1988',
  },
  {
    id: 2,
    title: 'Ghostbusters',
    year: '1984',
  },
]



function Details() {
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [isUploading, setIsUploading] = React.useState(true);
  const [files, setFiles] = React.useState([]);
  const [services, setServices] = React.useState(null)
  const [clients, setClients] = React.useState([])
  const [selectedClient, setSelectedClient] = React.useState(null)
  const [selectedService, setSelectedService] = React.useState(null)
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState();

  const handleChange = (file) => {
    setFiles(file);
  };

  // const handleFileChange = (newValue) => {
  //   setValue(newValue)
  // }

  const handleFileChange = (event) => {
    const files = Array.from(event);
    setSelectedFiles(files);
  };

  const handleRowSelected = React.useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = React.useMemo(() => {
    const handleDelete = () => {

      if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.title)}?`)) {
        setToggleCleared(!toggleCleared);
        setData(data.filter(item => !selectedRows.some(selected => selected.title === item.title)));
      }
    };

    return (
      <Button key="delete" onClick={handleDelete} style={{ backgroundColor: 'red' }} icon>
        Delete
      </Button>
    );
  }, [data, selectedRows, toggleCleared]);


  const top100Films = [
    { label: 'Kenya', year: 1994 },
    { label: 'Uganda', year: 1972 },
    { label: 'Tanzania', year: 1974 },
    { label: 'Ethiopia', year: 2008 },
    { label: 'Somalia', year: 1957 },
    { label: "Eritrea", year: 1993 },
  ]

  const topStatus = [
    { label: 'Pending', year: 1994 },
    { label: 'Approved', year: 1972 },
    { label: 'Rejected', year: 1972 },
  ]

  const handleCurrentSunday = () => {
    // Calculate the date for this Sunday
    const today = new Date();
    const sundayDate = new Date(today);
    sundayDate.setDate(today.getDate() + (0 - today.getDay()));

    setSelectedDate(sundayDate)
  }
  const fetchServices = () => {
    axios
      .get("https://localhost:44379/api/Services/GetServices")
      .then((response) => {
        console.log(response.data);
        setServices(response.data.$values)
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchClients = () => {
    axios
      .get("https://localhost:44379/api/Clients/GetClients")
      .then((response) => {
        console.log(response.data);
        setClients(response.data.$values)
      })
      .catch((e) => {
        console.log(e);
      });
  };


  const fetchTimeSheets = () => {
    axios
      .get("https://localhost:44379/api/TimeSheets/GetTimeSheets")
      .then((response) => {
        console.log(response.data);
        setData(response.data.$values)
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const UploadTimesheet = () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('timesheets', file);
    });

    // Make the API call to the endpoint that accepts multiple images
    fetch(`https://localhost:44379/api/TimeSheets/UploadTimeSheet?serviceId=b677125e-16c7-4e3a-92a0-08dba703469d&employeeId=acac3250-40d7-4e99-c9f4-08dba7032cb6&clientId=9c8db2ad-4b1c-4093-9b8f-08dba7034c60&WeekEndDate=27%2F08%2F2023`, {
      method: 'POST',
      body: formData,
    })
      .then((data) => {
        console.log(data.response);
        fetchTimeSheets()
      })
      .catch((error) => {
        // Handle any error that occurs during the upload
        console.error(error);
      });
  };


  const handleDocStatus = (status) => {
    switch (status) {
      case 'approved':
        return (<Chip
          label={status}
          size="small"
          variant="filled"
          style={{ backgroundColor: "#C2FBEF", color: "green" }}
        />)
        break;
      case 'rejected':
        return (
          <Chip
            label={status}
            size="small"
            variant="filled"
            style={{ backgroundColor: "#F4C2C2", color: "	#8B0000" }}
          />
        )
        break;

      default:
        return (
          <Chip
            label={status}
            size="small"
            variant="filled"
            style={{ backgroundColor: "yellow", color: "	#8B0000" }}
          />
        )
        break;
    }
  }


  const columns = [

    {
      name: 'Week-End Date',
      selector: row => row.weekEndDate,
      sortable: true,
    },
    {
      name: 'Client',
      selector: row => row.client.clientName,
      sortable: true,
    }, {
      name: 'Service',
      selector: row => row.service.serviceName,
      sortable: true,
    },
    {
      name: 'Submited On',
      selector: row => {
        return (
          moment(row.submittedOn).format('ll')
        )
      },
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => {
        return (
          handleDocStatus(row.status)
        )
      },
      sortable: true,
    },
    {
      name: 'Action',
      selector: row => {
        return (
          <div className='d-flex'>
            <Button
              variant="text"
              startIcon={<FileDownloadIcon />}
              size='small'
            >
              Download
            </Button>


          </div>)
      },
      sortable: true,
    },
  ];

  // Custom function to check if a date is not a Sunday of the current month
  const isNotSundayOfCurrentMonth = (date) => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const selectedMonth = date.getMonth();
    const dayOfWeek = date.getDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6

    // Disable dates that are not Sundays or are not in the current month
    return dayOfWeek !== 0 || currentMonth !== selectedMonth;
  };

  React.useEffect(() => {
    fetchClients()
    fetchServices()
    fetchTimeSheets()
    handleCurrentSunday()
  }, [])

  const containerStyle = {
    height: "25vh", // Set the desired height here
    border: "1px solid #ccc", // Add a border for visibility
    padding: "10px", // Optional: Add padding for spacing
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer"
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={containerStyle}>
        {
          isUploading ?
            <div className='d-flex flex-column'>
              <div className='d-flex w-100 justify-content-between px-5'>
                <div className="w-100">
                  <MuiFileInput
                    value={selectedFiles}
                    onChange={handleFileChange}
                    label="Time Sheet"
                    className='me-4'
                    multiple
                  />
                </div>
                <div className="w-100">
                  <LocalizationProvider dateAdapter={AdapterDateFns} >
                    <DatePicker
                      label="Week End Date"
                      value={selectedDate}
                      onChange={(e) => setSelectedDate}
                      shouldDisableDate={isNotSundayOfCurrentMonth}
                    />
                  </LocalizationProvider>
                </div>
                <FormControl className='w-100 mx-4'>
                  <InputLabel id="demo-simple-select-label">Client</InputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedClient?.clientName}
                    label="Client"
                    onChange={(e) => setSelectedClient(e.target.value)}
                  >
                    {
                      clients?.map((item) =>
                        <MenuItem value={item}>{item?.clientName}</MenuItem>
                      )
                    }

                  </Select>

                </FormControl>
                <FormControl className='w-100'>
                  <InputLabel id="demo-simple-select-label">Service</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedService?.clientName}
                    label="Service"
                    onChange={(e) => setSelectedService(e.target.value)}
                  >
                    {
                      services?.map((item) =>
                        <MenuItem value={item}>{item?.serviceName}</MenuItem>
                      )
                    }
                  </Select>
                </FormControl>
              </div>
              <div className="w-100 d-flex  justify-content-center align-items-center mt-5">
                <Button
                  variant="contained"
                  className='w-50 px-2'
                  onClick={() => UploadTimesheet()}
                >Upload Time Sheet</Button>
              </div>
            </div>
            :
            <img src={uploadIcon} alt="" srcset="" className='img-fluid' />
        }
      </div>

      <div className="title my-3">
        <Typography variant='h5'>
          Time Sheets
        </Typography>
      </div>

      <Paper
        // component="form"
        sx={{ p: '4px 2px', display: 'flex', alignItems: 'center' }}
        className='w-100  justify-content-end'
        elevation={0}
      >
        <div className='w-50 d-flex'>
          <div className="searchbox d-flex" style={{ background: "whitesmoke", flex: 2 }}>
            <IconButton sx={{ p: '10px' }} aria-label="menu">
              <ClearIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 2, background: "whitesmoke" }}
              placeholder="Search Document"
              inputProps={{ 'aria-label': 'search google maps' }}
              className='ps-2'
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <CalendarMonthIcon />
            </IconButton>
          </div>

          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

          <Autocomplete
            disablePortal
            size="small"
            id="combo-box-demo"
            options={topStatus}
            className='mx-3'
            sx={{ width: 200, flex: 1 }}
            renderInput={(params) => <TextField {...params} label="Status" />}
          />
        </div>


      </Paper>




      <DataTable
        columns={columns}
        data={data}
        className='w-100'
        fixedHeader
        fixedHeaderScrollHeight="45vh"
        pagination
        selectableRows
        contextActions={contextActions}
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
      />
    </div >
  );
};

export default Details