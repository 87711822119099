import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material'
import React from 'react'
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

const Profile = () => {
  return (
    <div className='d-flex justify-content-center pt-3 flex-column'>
      <Avatar
        alt="Remy Sharp"
        className='mx-3'
        src="https://avatars.githubusercontent.com/u/83855798?v=4"
        style={{ width: "220px", height: "220px" }}
      />
      <Divider className='mt-4' />
      <List sx={{ width: '100%', maxWidth: 360 }}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Full Name" secondary="Felix Ogundha" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <LocalPhoneIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Phone Number" secondary="0718627569" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <EmailIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Email Address" secondary="felix.ogundha@gmail.com" />
        </ListItem>

        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <BusinessCenterIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Job Role" secondary="Health Care Assitant" />
        </ListItem>

      </List>
    </div>
  )
}

export default Profile