import { Avatar, Typography } from '@mui/material'
import React from 'react'
import tabitha from '../../../assets/users/tabitha.webp'

const MHeader = () => {
  return (
    <div className='container pt-3'>
        <div className="d-flex  justify-content-between">
        <div className="d-flex flex-column">
        <Typography variant='subtitle1'>
            Hi, Jane
        </Typography>
        <Typography variant='h5'>
           Manage your timesheets
        </Typography>
        </div>
        <Avatar alt="Remy Sharp" src={tabitha} style={{
            width:"90px",
            height:"90px"
        }} />
        </div>
    </div>
  )
}

export default MHeader