import { Avatar, Divider, Typography } from '@mui/material'
import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Container } from 'react-bootstrap';

const BackendHeader = () => {
  return (
    <div >

      <div className="d-flex  py-3 justify-content-between " style={{ padding: '16px 24px', }}>
        <div className="name">

        </div>
        <div className="profile-details d-flex align-items-center">
          <div className="fullname px-3">
            <Typography variant='subtitle2' sx={{ p: 0, m: 0 }}>
              Felix Ogundha
            </Typography>

          </div>
          <div className="profile d-flex align-items-center">
            <Avatar
              alt="Remy Sharp"
              src="https://avatars.githubusercontent.com/u/83855798?v=4"
              style={{ width: "25px", height: "25px" }}
            />
            <ExpandMoreIcon style={{ color: "grey" }} fontSize='small' />
          </div>
        </div>
      </div>

    </div>
  )
}

export default BackendHeader