import { BarChart, Book } from '@mui/icons-material';
import React, { useState } from 'react';
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
  MenuItemStyles,
} from 'react-pro-sidebar'; // Update with the correct import path for react-pro-sidebar
import SidebarFooter from '../../components/SidebarFooter';
// import  from '../../components/Switch';
import SidebarHeader from '../../components/SidebarHeader';
import { Typography, Switch, Divider } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BackendHeader from '../../components/partials/BackendHeader';
import Employees from '../../components/Data/Employees';
import MainDashboard from '../../components/Data/Dashboard';
import CampaignIcon from '@mui/icons-material/Campaign';

const themes = {
  light: {
    sidebar: {
      backgroundColor: '#ffffff',
      color: '#607489',
    },
    menu: {
      menuContent: '#fbfcfd',
      icon: '#0098e5',
      hover: {
        backgroundColor: '#c5e4ff',
        color: '#44596e',
      },
      disabled: {
        color: '#9fb6cf',
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: '#0b2948',
      color: '#8ba1b7',
    },
    menu: {
      menuContent: '#082440',
      icon: '#59d0ff',
      hover: {
        backgroundColor: '#00458b',
        color: '#b6c8d9',
      },
      disabled: {
        color: '#3e5e7e',
      },
    },
  },
};

const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const Index = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(false);
  const [rtl, setRtl] = useState(false);
  const [hasImage, setHasImage] = useState(false);
  const [theme, setTheme] = useState('light');
  const [selectedTab, setSelectedTab] = useState('employees');
  const [selectedService, setSelectedService] = useState("Finance");

  const handleRTLChange = (e) => {
    setRtl(e.target.checked);
  };

  const handleThemeChange = (e) => {
    setTheme(e.target.checked ? 'dark' : 'light');
  };

  const handleImageChange = (e) => {
    setHasImage(e.target.checked);
  };

  const menuItemStyles = {
    root: {
      fontSize: '13px',
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: '#b6b7b9',
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(themes[theme].menu.menuContent, hasImage && !collapsed ? 0.4 : 1)
          : 'transparent',
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      '&:hover': {
        backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, hasImage ? 0.8 : 1),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  const HandleSelectedTab = (x) => {
    switch (x) {
    
      case 'employees':
        return (
          <Employees />
        )
        break;
    
      case 'dashboard':
        // return (
        //   // <MainDashboard />
        // )
        break;

      default:
        return (
          <Employees />
        )
        break;
    }
  }

  return (
    <div style={{ display: 'flex', minHeight: '100vh', direction: rtl ? 'rtl' : 'ltr',backgroundColor: 'whitesmoke', }}>
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        onBreakPoint={setBroken}
        image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
        rtl={rtl}
        breakPoint="md"
        backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, hasImage ? 0.9 : 1)}
        rootStyles={{
          color: themes[theme].sidebar.color,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <SidebarHeader rtl={rtl} style={{ marginBottom: '24px', marginTop: '16px' }} />
          <div style={{ flex: 1, marginBottom: '32px' }}>
            {/* <Menu menuItemStyles={menuItemStyles}>
              <MenuItem
                icon={<DashboardIcon />}
                onClick={() => {
                  setSelectedTab("dashboard")
                }}
              >
                Dashboard
              </MenuItem>
            </Menu> */}

            <div style={{ padding: '0 24px', marginBottom: '8px', marginTop: '10px' }}>
              <Typography
                variant="body2"
                fontWeight={600}
                style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px' }}
              >
                General
              </Typography>
            </div>
            <Menu menuItemStyles={menuItemStyles}
            >

             

              <MenuItem
                icon={<CampaignIcon />}
                onClick={() => setSelectedTab("employees")}
              >
                Communication
              </MenuItem>
           
             
            </Menu>


          </div>
          <SidebarFooter collapsed={collapsed} />
        </div>
      </Sidebar>

      <main className=' w-100'>
        <div className="heading bg-white" >
          <BackendHeader />
        </div>
        <Divider />

        <div style={{ padding: '10px 40px', backgroundColor: 'whitesmoke', }}>
          <div style={{ marginBottom: '16px' }}>
            {broken && (
              <button className="sb-button" onClick={() => setToggled(!toggled)}>
                Toggle
              </button>
            )}
          </div>
          <div className=" w-100">
            {
              HandleSelectedTab(selectedTab)
            }
          </div>
        </div>


      </main>
    </div>
  );
};

export default Index;
